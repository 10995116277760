<script context="module">
  import { ripple } from './ripple.action'
  export { ripple }
</script>

<script>
  // See https://codepen.io/VeronQ/details/LdYRBV
</script>

<style type="text/css">
  :global(.ripple) {
    overflow: hidden;
    position: relative;
  }

  :global(.ripple-effect) {
    pointer-events: none;
    width: 2px;
    height: 2px;
    position: absolute;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);

    animation: rippleEffect 0.5s ease-in-out;
  }

  @keyframes -global-rippleEffect {
    0% {
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(var(--scale));
    }
  }
</style>
