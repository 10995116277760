<div on:click />

<style>
  div {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5000;
    background: rgba(0, 0, 0, 0.3);
  }
</style>
